// SETTINGS

// please delete / disable /modules/_ruffnav.scss cause it is not needed

$break: 	giant; 	// break from mobile to desktop navigation
$barHeight: 50px; 	// height for mobile quickbar
$time: 		0.3s; 	// transition for some stuff

// MOBILE NAVIGATION

@include breakpoint($break, max) {

	#navigation {
		transition:transform $time;
		background-color:$light;

		ul {
			background-color:$light;

			li {
				display: flex;
				width: 100%;
                margin-right: rem(7px);

				a, span {
					align-items: center;
					border-bottom: rem(1px) solid rgba($dark,0.1);
					color:$dark;
					display: flex;
					font-size:rem(16px);
					line-height: rem(24px);
					padding:rem(15px) rem($base-gap);
					text-decoration: none;
					width: 100%;
					transition:background $time, color $time;

					&.sub-toggler {

						&:after {
							font-family: $icon-font;
							content: '\ea1f';
						};
					}
				};

				&.nav-back {
                    background:$medium;

					span {
						justify-content: flex-start;

						&:before {
							font-family: $icon-font;
							content: '\ea1f';
							transform:rotate(180deg) translateY(rem(1px));
							margin-right: rem(7px);
						}
					}
				}
			}

            // active status for all elements

            a.active, span.active, .has-sub.active > a, .has-sub.active > span, &.open .navItem.active a {
                background:$primary;
                color:$light;
            }
		}

		//////////////////////////////////////////////////////////////////////////////////////////// 
		//essential styles for an working mobile subnavigation
		// do not change this, unless you know what you do
		
		min-width:90%;
		max-width: 100%;
		overflow:hidden;
		position:fixed;
		right:0;
	 	z-index:1002;
        bottom:0;
        top:rem($barHeight);

	 	nav {
	 		overflow: hidden;
    		overflow-y: auto;
    		height:calc(100vh - #{rem($barHeight)});

    		ul {
    			padding-bottom: rem(100px); // fix for mobile phones with a toolbar at the end
    			width:100%;

    			&.sub {
					position:absolute;
					left:0;
					top:0;
					transform:translateX(100%);
					height:calc(100vh - #{rem($barHeight)});
					transition:transform $time;
				}

				&.current {
					transform:translateX(0%);

					&.open {
						z-index: 1;
						overflow:hidden;
						overflow-y:auto;
						-webkit-overflow-scrolling:touch;
					}
				}
    		}
	 	}

	 	//////////////////////////////////////////////////////////////////////////////////////////// 
	 	//////////////////////////////////////////////////////////////////////////////////////////// 
	}

	html.hidden-scroll {
		overflow:hidden;
	}
 
}


// DESKTOP NAVIGATION

@include breakpoint($break) {

	#navigation {
		position: relative;
		z-index: 100;
        transform: translateY(100%);
        padding-left: rem(70px);

        .navi {
            display: flex;
            justify-content: space-between;

            /// main styles
            //////////////////////////////////////////////////////////////

            li {
                display: flex;
                align-items: center;
                position: relative;
            }

            a, span {
                font-size:rem(18px);
                white-space: nowrap;
                color: $light;
                display: flex;
                justify-content: space-between;
                align-items: center;
                text-decoration: none;
                cursor: pointer;
                line-height: 1;
                position: relative;
                width: 100%;

				@include breakpoint(huge) {
					font-size: rem(20px);
				}
            }

            .impressum, .datenschutzerklaerung, .anfahrt {
                display: none;
            }

            /// only first level
            //////////////////////////////////////////////////////////////

            > li {

                > a, > span {
                    padding-bottom: rem(7px);
                }

                // active and hover status

                > a.active, > span.active {
                    color: $light;
					font-weight: 600;
                }

                a {
                    
                 &:hover {
                        opacity: .5;
                    }
                }

                // active and hover status

                > a.active, > span.active, &:hover > a, &:hover > span {
                    
                    &:before {
                        top:100%;
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }

            /// navigation with sub-menus
            //////////////////////////////////////////////////////////////

            li.has-sub {

                > a, > span {

                    &:after {
                        font-family: $icon-font;
                        content: '\ea1f';
                        transform: rotate(90deg) translateX(0px);
                        margin-left: rem(6px);
                        transform-origin: center;
                    };
                }

                &:hover > .sub, ul.current {
                    opacity: 1;
                    visibility: visible;
                    pointer-events: auto;
                }
            }

            ul.sub {
                background: $light;
                position: absolute;
                top: 100%;
                left: 0;
                min-width: 100%;
                opacity: 0;
                visibility: hidden;
                pointer-events: none;
                transition: opacity $time, visibility $time;
                box-shadow: 0 0 rem(10px) rgba(black,0.05);

                li {

                    a, span {
                        transition:all $time;
                        padding:rem(15px) rem($base-gap);
                    }

                    // active and hover status for all sub a elements

                    a.active, span.active, &:hover > a, &:hover > span, a:hover, span:hover {
                        background:$primary;
                        color:$light;
                    }

                    &.navBack {
                        text-align: center;
        
                        span {
                            display: none;
                        }
        
                        &:before {
                            cursor: pointer;
                            width: 100%;
                            font-family: $icon-font;
                            content: map-get($icon-map, times);
                            display: block;
                            font-size: rem(16px);
                            padding: rem(5px) rem($base-gap);
                            background: $medium;
                            transition: background $time;
                        }
        
                        body.desktop & {
                            display: none;
                        }
                    }
                }

                /// sub in sub
                //////////////////////////////////////////////////////////////

                // Here we want the sub-menu to the right of the parent menu

                ul.sub {
                    left: 100%;
                    top: 0;
                }

                li.has-sub {

                    > a, > span {

                        &:after {
                            transform: rotate(0deg) translateX(0px);
                        };
                    }
                }
            }

        }
	}
}

.cms-template-edit {

    .fixed-wrapper {
        position: relative;
    }
}

body:not(.cms-template-edit) {
	.fixed-wrapper {

		@include breakpoint(giant) {
			background-color: $light;
			position: fixed;
			width: 100%;
			margin-top: 0;
			top: 0;
			left: 0;
			z-index: 2000;
            box-shadow: 0 0 rem( 15px ) 0 rgba( black, .15 );
		}
	}
}

// MOBILE QUICKBAR

#quickbar {
    display:none;

    @include breakpoint($break, max) {
        box-shadow:0 0 rem(10px) rgba(black,0.1);
        height:rem($barHeight);
        background-color:$primary;
        display:flex;
        justify-content:space-between;
        align-items:center;
        z-index:1000;
        position:fixed;
        top: 0;
        left:0;
        width:100%;
        padding:0 rem($base-gap);
        transform:translateY(0%);
        transition:transform $time;

        label[for="navi-toggled"] {
            display:block;
            cursor:pointer;
            width:rem(20px);
            height:rem(20px);
            position:relative;
            overflow:hidden;

            &:after{
                content:"";
                position:fixed;
                left:0;
                width:200vw;
                height:200vh;
                display:block;
                background-color:rgba($dark,0.9);
                z-index:-1;
                top:rem($barHeight);
                transition: opacity $time, visibility $time;
            }

            span {
                transition:0.4s linear;
                text-indent:-9999px;
                width:0;
                top:50%;
                transform:translate(-50%, -50%);

                &:before, &:after {
                    top:50%;
                    width:rem(20px);
                    transition:top $time ease-in-out, transform $time 0.2s linear;
                }

                &:before {
                    transform:translate(-50%, -50%) rotate(45deg);
                };

                &:after {
                    transform:translate(-50%, -50%) rotate(-45deg)
                };
            }

            span, span:before, span:after {
                content:"";
                position:absolute;
                display:block;
                background:$light;
                height:2px;
                left:50%;
                transform-origin:center
            };
        };
    };
};

// <input> Checkbox for Off-Canvas-Navigation handling

#navi-toggled {
    display: none;

    @include breakpoint($break, max) {
        &:not(:checked) {

            & ~ * #navigation,
            & ~ #navigation {
                transform: translateX(100%);
            };

            & ~ #quickbar {
                
                label[for="navi-toggled"] {

                    span {
                        width: 20px;

                        &:before, &:after {
                            transform: translate(-50%, -50%);
                            transition: top $time 0.2s ease-in-out, transform $time linear;
                        };

                        &:before {
                            top: calc(50% - 5px);
                        };

                        &:after {
                            top: calc(50% + 5px);
                        };
                    }

                    &:after {
                        opacity: 0;
                        visibility: hidden
                    };
                };
            };
        };
    };
};