.notification-area {
	position: fixed;
	bottom: rem(68px);
	left: rem(40px);
	z-index: 999999;
	visibility: hidden;
	display: block;
		
	@include breakpoint(large) {
		left: rem(60px);
	}
	
	> :not(:first-child):not(.hide).transition-in {
		margin-top: $base-gap;
	}
	
	.notification {
		display: block;
		position: relative;
		transition: 300ms all ease-in-out;
		overflow: hidden;
		padding:0;
		background: $alert;
		font-size: rem(12px);
		line-height: rem(16px);
		box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.35);
		width: rem(300px);
		max-width: calc(100vw - 80px);
		transform-origin: left center;
		visibility: hidden;
		opacity: 0;
		max-height:none;
		height: 0;
		box-sizing:border-box;
		margin-left:-100px;
	
		.note-content {
			padding:1rem;
			display: block;
		}
	
		@include breakpoint(large) {
			font-size:rem(16px);
			line-height: rem(22px);
		}
	
		&.transition-in {
			visibility: visible;
			opacity: 1;
			max-height:9999999px;
			height: auto;
			margin-left:0;
		}
	
		&,
		a {
			color: $light;
		}
	
		&.success {
			background: #333;
		
			&, .close {
				color: #fff;
			}
		}
		&.error {
			background: $alert
		}
		&.success, &.error {
			p {
				margin-bottom: rem(5px);
			}
			p + ul {
				margin-top: rem(5px);
			}
		}
	
		.close {
			position: absolute;
			top: 0;
			right: 0;
			padding-top: 5px;
			padding-right: 8px;
			cursor: pointer;
			color: #fff;
			font-size: 1.2rem;
		}
	
		&.hide {
			margin-left:-100px;
			opacity: 0;
			visibility: hidden;
			height:0;
		}
	}
}
