// Form styles
form.default {
	overflow: hidden;
	width: 100%;

	&.disabled {
		input, textarea, select, button, label {
			pointer-events: none;
			opacity:0.5;

			a
			{
				pointer-events: all;
			}
		}
	}

	::-webkit-input-placeholder {
		/* Chrome/Opera/Safari */
		color: $dark;
	}

	::-moz-placeholder {
		/* Firefox 19+ */
		color: $dark;
	}

	:-ms-input-placeholder {
		/* IE 10+ */
		color: $dark !important;
	}

	:-moz-placeholder {
		/* Firefox 18- */
		color: $dark;
	}

	fieldset {
		border: none;
		margin: 0 0 3.5rem 0;
	}

	label,
	input,
	textarea,
	select,
	button,
	[type="submit"] {
		// min-height: 3.5rem;
	}

	legend, span.legend {
		@extend .h2;
		margin-top: 2rem;
	}

	label {
		color: inherit;
		display: block;
		padding: 1rem 0 0.6rem;
		position: relative;
		clear:both;

		small {
			color: $alert;
			display: inline-block;
			line-height: 0.5rem;
		}

		&[for*="privacy-policy"],  &[for*="datenschutz"], 
		&[for*="rueckrufbitte-ja"], &[for*="callback-yes"] {
			// @extend .margin-bottom ;
			float: right;
			width: calc(100% - #{rem($base-font-size) * 2});
		}
	}

	div[data-field-error] {
		position: relative;
		background: $alert;
		padding: .5rem;
		font-size: .85rem;
		line-height: 1;
		color: $light;
		white-space: nowrap;
		overflow-x: auto;
		width: 100%;
	}

	small {
		line-height: 1rem;
		display: inline-block;
		font-size:0;
		&:before{
			font-size:1rem;
			padding-left:rem(6px);
		}
		body.lang-en &{
			&:before{
				content:'(Required)';
			}
		}
		body.lang-de &{
			&:before{
				content:'(Pflichtfeld)';
			}
		}
	}

	&.cms-map {
		small {
			font-size: rem(12px);

			&:before {
				display: none;
			}
		}
	}

	[type="text"],
	[type="tel"],
	[type="email"],
	[type="file"],
	input,
	textarea,
	select {
		@extend %animated-transform;
		background: $medium;
		border: $base-border;
		color: $dark;
		display: block;
		font-family: $main-font;
		font-size: rem($base-font-size);
		line-height: rem($base-line-height);
		margin: 0 0 0.5rem 0;
		padding: 0.8rem;
		position: relative;
		resize: none;
		width: 100%;
		border-radius: 0;

		&:focus {
			background: darken($light, 2%);
			outline: none;
		}
	}

	textarea {
		/* fake line-height */
		min-height: 7.5rem;
		padding-top: 0.9rem;

		$inputgroup-count: 3;

		&[name="Nachricht"] {
			min-height: 7.5rem * $inputgroup-count - 4rem;
		}
		&[name="Text"] {
			min-height: 7.5rem * $inputgroup-count - 4rem;
		}
	}

	[type="checkbox"] {
		display: inline-block;
		margin: 0 rem($base-font-size) 0 0;
		padding: 0;
		width: rem($base-font-size );
		height: rem($base-font-size );
		flex-shrink: 0;

		&[id*="privacy-policy"],  &[id*="datenschutz"], 
		&[id*="rueckrufbitte-ja"], &[id*="callback-yes"] {
			margin-top: calc( ( #{rem($base-line-height)} - #{rem($base-font-size)} ) / 2 + 1rem) ;
			// @extend .margin-bottom;
		}

		body.iexplore & {
			border: none;

			&:focus {
				background: none;
			}
		}
	}

	[type="radio"] {
		margin: 0 rem(15px) 0 0;
        width: rem(15px);
		min-height: auto;
		flex-shrink: 0;
	}

	button,
	[type="submit"] {
		@extend .btn;
		margin-top: 1rem;
		margin-bottom: 1rem;
		width: 100%;
    }
}
div.error {
    background: $alert;
    color: $light;
    padding: .5rem;
	max-width: calc(#{$rowMaxWidth} - #{$base-gap} * 2);
    margin: auto;
    width: calc(100% - #{$base-gap} * 2);
}
.contact-text { 
	border-top:1px solid $border;
	border-bottom:1px solid $border;
	padding:rem(30px) 0;
	margin:rem(30px) 0 rem(40px) 0;
	p{
		margin-bottom:0;
	}
}
/*=VALIDATION */

.specialfield {
	display: none !important;
	visibility: hidden !important;
}

#newsletter-info {
	background: $alert;
	color: $light;
	font-weight: bold;
	margin-bottom: $base-line-height;
	padding: $base-gap;

	*:last-of-type {
		margin-bottom: 0;
	}
}

img[name="vimg"] + input[name="imgverify"] {
	float: right;
}
