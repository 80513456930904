.icon {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	display: inline-block;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	line-height: 1;
	font-family: $icon-font;
}

@each $name, $ico in $icon-map {
	// .icon-#{$name}
	.icon-#{$name}:before {
		content: "#{$ico}";
	}
}
