.branding {
	position: relative;
	z-index: 15;
	max-width: 100%;
	margin-top:rem($barHeight);
	text-align: center;
	padding: rem(20px) rem(20px);
	transition: .3s;

	body:not(.index) & { 
		padding: rem(10px) rem(10px);
	}

	@include breakpoint(giant) {
		margin-top: 0;
		padding: 0;
	}

	img {
		width: rem(180px);

		body:not(.index) & { 
			width: rem(120px);
		}
	}

	&:hover {
		opacity: .6;
	}
}

.navi-container {
	
	@include breakpoint(giant) {

		&:before {
			content: '';
			position: absolute;
			width: 100vw;
			height: 100%;
			top: 0;
			left: auto;
			background-color: $primary;
		}
	}
}

// HEADER
//////////////////////////////

#header {
	position: relative;
	width: 100%;

	@include breakpoint(giant) {
		margin-top: rem(75px);
	}

		body:not(.cms-page-edit) & {

			.swiper-container {
				height: 100%;
				overflow: hidden;
				position: relative;

				.row {
						img{
							height: 150px;
							width: 100%;
							object-fit: cover;   
							
							@include breakpoint(giant) {
								height: 350px;
							}
						}
					}
				}

			.swiper-wrapper {
				display:flex;
			}

			.cms-template-edit & {
				position: relative;
				top:auto;
				height:auto;
			}

			.headerbild {

				img {
					height: 500px;
					width: 100%;
					object-fit: contain;
				}

			.swiper-button-next, .swiper-button-prev {
				display:none;
			}
		}

		.header-container {
			position: relative;
			width: 100%;
		
			img {
				margin-bottom: 0;
				height: rem(300px);
				object-fit: cover;

				@include breakpoint(giant) {
					height: rem(450px);
				}
			}
		
		.header-text {
			position: absolute; 
			width: 100%;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			text-align: center;
			
			
			@include breakpoint(medium) {
				white-space: nowrap;
			}
				
				span.headline {
					font-size: clamp(20px, calc(1rem + 1vw), 48px);
					color: $light;
					font-weight: 800;
					line-height: 120%;
					text-shadow: 2px 1px $dark;

					@include breakpoint(cut) {
						font-size: 48px;
					}
				}

				span.subline {
					font-size: clamp(18px, calc(1rem + 1vw), 43px);
					color: $light;
					font-weight: 300;
					line-height: 120%;
					text-shadow: 2px 1px $dark;

					@include breakpoint(cut) {
						font-size: 43px;
					}
				}

				.btn, .btn-border {
					margin-top: rem(15px);
					
					@include breakpoint(tiny) {
						margin-right: rem(20px);
						&:last-child {
							margin-right: 0;
						}
					}

					@include breakpoint(giant) {
						margin-top: rem(30px);
					}

					a {
						text-decoration: none;
						color: $light;
					}
				}
			}
		}
	}

	.sub-header {

		img {
			margin-bottom: 0;
			height: rem(100px);
			object-fit: cover;

			@include breakpoint(giant) {
				height: rem(200px);
			}
		}
	}
}

// MAIN CONTENT
//////////////////////////////

main {
	display: block;
	
	p,
	ol,
	ul,
	dl,
	table {
		@extend .margin-bottom ;
	}

	ul:not(.unstyled-list),
	dl:not(.unstyled-list),
	ol:not(.unstyled-list) {
		@extend .styled-list;
	}

	img {
		margin-bottom:rem($base-gap*2);
		transition: .3s;

		body:not(.index) & {

			&:hover {
				filter: brightness(120%);
			}
		}
	}

	.divider {
		position: relative;
		width: 100%;
	
		img {
			margin-bottom: 0;
			height: rem(300px);
			object-fit: cover;

			@include breakpoint(giant) {
				height: rem(450px);
			}
		}

		.divider-text {
			position: absolute; 
			top: 40%;
			left: 0;
			width: 100%;
			height: auto;

			@include breakpoint(giant, max) {
				text-align: center;
			}

			@include breakpoint(giant) {
				transform: translate(0, -50%);
				top: 50%;
				left: 50%;
			}
				
				span.headline {
					font-size: clamp(20px, calc(1rem + 1vw), 48px);
					color: $light;
					font-weight: 800;
					line-height: 120%;

					@include breakpoint(cut) {
						font-size: 48px;
					}
				}

				span.subline {
					font-size: clamp(18px, calc(1rem + 1vw), 43px);
					color: $light;
					font-weight: 300;
					line-height: 120%;

					@include breakpoint(cut) {
						font-size: 43px;
					}
				}

				.btn-border {
					margin-top: rem(15px);

					@include breakpoint(giant) {
						margin-top: rem(30px);
					}

					a {
						text-decoration: none;
						color: $light;
					}
				}
			}
		}

	.teaserSwiper {
		padding-bottom: rem(40px);
	}

	.swiper-container {
		position: relative;
		top: 0;
		left: 0;
		right: 0;
		height: auto;
		margin-top: 0;
		width: 100%;
		z-index: 80;
		opacity: 1;

		figure {
			width: 100%;
			height: 100% !important;

			img {
				@include object-fit(cover);
				//position: absolute;
				max-width: none;
				height: 100%;
				max-height: 100%;
				width: 100%;       
				margin-bottom: 0;  
				filter: brightness(70%);
				transition: .3s;
				position: relative;

				&:hover {
					filter: brightness(100%);
				}
			}
		}

		.swiper-wrapper {
			display: flex;
		}

		.teaser-img-container {
			position: relative;
			width: 100%;

			.teaser-text {
				position: absolute;
				background-color: rgba(190, 21, 34, 0.5);
				padding: 15px 15px;
				bottom: 0;
				left: 0;
				text-align: center;
				height: auto;
				width: 100%;
				color: $light;
				font-size: clamp(20px, calc(1vw + .5rem), 38px);
				font-weight: 500;
				transition: .2s;

				&:hover {
					background-color: rgba(43, 43, 43, 0.5);
				}
	
				p {
					margin-bottom: 0;
				}

				@include breakpoint(giant) {
					padding: 30px 30px;
				}
			}
		}
	}

	.divider {
		position: relative;
		width: 100%;
	
		img {
			margin-bottom: 0;
			height: rem(300px);
			object-fit: cover;

			@include breakpoint(giant) {
				height: rem(450px);
			}
		}

	.divider-text-2 {
		position: absolute; 
		top: 30%;
		left: 0;
		width: 100%;
		height: auto;

		@include breakpoint(giant, max) {
			text-align: center;
		}

		@include breakpoint(giant) {
			transform: translate(0, -50%);
			top: 50%;
			left: 15%;
		}
			
			span.headline {
				font-size: clamp(20px, calc(1rem + 1vw), 48px);
				color: $light;
				font-weight: 800;
				line-height: 120%;

				@include breakpoint(cut) {
					font-size: 48px;
				}
			}

			span.subline {
				font-size: clamp(18px, calc(1rem + 1vw), 43px);
				color: $light;
				font-weight: 300;
				line-height: 120%;

				@include breakpoint(cut) {
					font-size: 43px;
				}
			}

			.btn-border {

				a {
					text-decoration: none;
					color: $light;
				}

				p {
					margin-bottom: 0;
				}
			}
		}
	}

	.galerieSwiper {
		padding-top: rem(75px);
		height: 350px;
		width: auto;
		object-fit: contain;

		@include breakpoint(giant) {
			padding-top: rem(150px);
			height: 500px;
		}
	}
}


#privacy-policy {
	a {
		display: inline-block; 
		word-break: break-all;
	}
}

// FOOTER
//////////////////////////////

#footer {

	body:not(.cms-template-edit) & {

	.img-container {
		position: relative;
		width: 100%;
	
		img {
			margin-bottom: 0;
			height: rem(300px);
			object-fit: cover;

			@include breakpoint(giant) {
				height: rem(450px);
			}
		}
	
		.img-text {
			position: absolute; 
			top: 20%;
			left: 0;
			width: 100%;
			height: auto;

			@include breakpoint(tiny) {
				top: 25%;
			}

			@include breakpoint(giant, max) {
				text-align: center;
			}

			@include breakpoint(giant) {
				transform: translate(0, -50%);
				top: 50%;
				left: 50%;
			}
				
				span.headline {
					font-size: clamp(20px, calc(1rem + 1vw), 48px);
					color: $light;
					font-weight: 800;
					line-height: 120%;

					@include breakpoint(cut) {
						font-size: 48px;
					}
				}
	
				span.subline {
					font-size: clamp(18px, calc(1rem + 1vw), 43px);
					color: $light;
					font-weight: 300;
					line-height: 120%;

					@include breakpoint(cut) {
						font-size: 43px;
					}
				}

				.btn-border {
					margin-top: rem(15px);
					
					@include breakpoint(tiny) {
						margin-right: rem(20px);
						&:last-child {
							margin-right: 0;
						}
					}
					@include breakpoint(giant) {
						margin-top: rem(30px);
					}

					a {
						text-decoration: none;
						color: $light;
					}
				}
			}
		}
	}

	.footer-container {
		font-weight: 300;
		font-size: rem(19px);
		padding-top: rem(60px);
		padding-bottom: rem(120px);
		white-space: nowrap;

		@include breakpoint(large) {
			font-size: rem(16px);
			padding-bottom: rem(60px);
		}

		a {
			color: $dark;
			text-decoration: none;
			font-weight: 300;

			&:hover {
				color: $hover;
			}
		}

		.footer-navi {
			margin-top: rem(30px);

			@include breakpoint(small) {
				margin-top: 0;
			}
		}

		.email {
			color: $primary;
			font-weight: 500;
		}

		ul {
			text-align: center;

			@include breakpoint(medium) {
				text-align: left;
			}

			@include breakpoint(huge) {
				display: flex;
				justify-content: space-evenly;
			}

			&.active {
				font-weight: 500;
				color: $primary;
			}
		}

		i {
			color: $primary;
		}
	}
}
