// Use this for default Icomoon

@include font-face-V2(
    $font-name: "icomoon",
    $file-name: "icomoon",
    $types: (woff),
    $style: normal,
);

// Example font-face for Roboto
@include font-face-V2(
    $font-name: "Rubik", 
    $file-name: "rubik-v14-latin", 
    $weights: ("300", "400", "500", "600", "700", "800"), 
    $types: (woff, woff2),
);