.margin-bottom	{
	margin-bottom: rem($base-line-height);
}

.highlight {
	color: $secondary;
}

.section {
	padding-top: rem(75px);
	padding-bottom: rem(75px);

	@include breakpoint(giant) {
		padding-top: rem(150px);
		padding-bottom: rem(150px);
	}
}

.text-center {
	text-align: center;
}

.margin-top {
	margin-top: rem(25px);

	@include breakpoint(giant) {
		margin-top: rem(50px);
	}
}

.btn {
	@extend %animated-transform;
	appearance: none;
	background: $dark;
	border: none;
	color: $light;
	cursor: pointer;
	display: inline-block;
	font-size: rem($base-font-size);
	line-height: rem($base-line-height);
	padding: 0.8rem 1rem;
	text-align: center;
	text-decoration: none;
	font-family: $main-font;

	&:hover {
		background: $primary;
		color: $light;
	}
	&:active {
		background: lighten($dark, 30%);
		color: $light;
	}
	&:focus {
		background: lighten($dark, 45%);
		color: $light;
	}

	*[class^="icon"] {
		margin-left: 0.5rem;
		vertical-align: middle;
	}
}

.btn-border {
	@extend %animated-transform;
	appearance: none;
	background: none;
	border: solid 1px $light;
	color: $light;
	cursor: pointer;
	display: inline-block;
	font-size: rem($base-font-size);
	line-height: rem($base-line-height);
	padding: 0.8rem 1rem;
	text-align: center;
	text-decoration: none;
	font-family: $main-font;

	&:hover {
		background: $dark;
		color: $light;
		border: 1px solid $dark;
	}
	&:active {
		background: $primary;
		color: $light;
		border: 1px solid $primary;
	}
	&:focus {
		background: lighten($dark, 45%);
		color: $light;
	}

	*[class^="icon"] {
		margin-left: 0.5rem;
		vertical-align: middle;
	}
}

.clear {
	clear: both;
	float: none;
}

.clearfix {
	@include pie-clearfix;
}

.hidden {
	display: none !important;
}

.hideText {
	@include hide-text();
	display: inline-block;
	overflow: hidden;
	width: 0;
}

.float-left {
	float: left;
}

img.float-left {
	margin-right: 1rem;
}

.float-right {
	float: right;
}

img.float-right {
	margin-left: 1rem;
}

.fluid {
	height: auto;
	width: 100%;
}

.non-fluid {
	width: auto !important;
}

.align-left {
	text-align: left;
}

.align-right {
	text-align: right;
}

.align-center {
	text-align: center;
}

.wide {
	width: 100%;
	display: block;
}

/* 16:9 video resolutions */
.videoContainer {
	&:not(.videoTag) {
	  position: relative;
	  height: 0;
	  overflow: hidden;
	  padding-bottom: 56.25%;
	  position: relative;
  
	  iframe {
		border: none;
		height: 100%;
		position: absolute;
		width: 100%;
		top: 0;
		left: 0;
	  }
	}
  
	video {
	  width: 100%;
	  height: auto;
	}
  }
  
  .google-maps {
  
	height: rem(300px);
  
	@include breakpoint(medium) {
	  height: rem(400px);
	}
  
	@include breakpoint(giant) {
	  height: rem(500px);
	}
  }

body {
	&:before {
		$content: "default:" + $bpContext;
		width: str_length($content) * 6pt + $base-gap * 2;

		@each $point, $width in $gridMap {
			$content: $content + "...." + $point + ":" + nth(map-get(map-get($gridMap, $point), width), 1);

			@include breakpoint($point) {
				width: str_length($content) * 6pt + $base-gap * 2;
			}
		}

		content: "#{$content}";
		display: none !important; /* Prevent from displaying. */
	}

	&.dev-mode:before,
	&.debug:before {
		background: $alert;
		border-color: $alert;
		border-style: solid;
		border-width: 0 rem($base-gap);
		color: $light;
		display: block !important;
		font-family: Courier;
		font-size: 10pt;
		left: 0;
		line-height: 2.5;
		overflow: hidden;
		position: absolute;
		right: 0;
		text-align: center;
		top: 0;
		white-space: nowrap;
		z-index: 99999;
	}
	&:hover:before {
		opacity: 0.5;
		pointer-events: none;
	}
}

// Animations
%animated-transform {
	transition: 0.3s;
}

html.in-progress {
	pointer-events: none;
	&:before {
		content:"";
		width: 100vw;
		height: 100vh;
		background:rgba(0,0,0,0.5);
		position: fixed;
		top:0;
		left:0;
		z-index:99999998
	}
	&:after {
		content:"";
		position: fixed;
		top: 0;
		height: 0;
		background: url('/images/layout/loading.svg') no-repeat center center;
		height: 100vh;
		width: 100vw;
		z-index:99999999;
		filter:invert(100%);
	}
}

// cms-4 grid system classes
.row {
	//vertical alignment
	&.stretch {
		align-items: stretch;
	}
	&.top {
		align-items: flex-start;
	}
	&.middle {
		align-items: center;
	}
	&.bottom {
		align-items: flex-end;
	}
	//horizontal alignment
	&.start {
		justify-content: flex-start;
	}
	&.center {
		justify-content: center;
	}
	&.end {
		justify-content: flex-end;
	}
	&.around {
		justify-content: space-around;
	}
	&.between {
		justify-content: space-between;
	}
}